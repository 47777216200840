import { withDependencies } from '@wix/thunderbolt-ioc'
import { BrowserWindow, BrowserWindowSymbol, IPageDidMountHandler } from '@wix/thunderbolt-symbols'
import { LinkClickHandlers } from './symbols'
import { ILinkClickHandlers } from './types'

type HTMLElementTarget = HTMLElement | null
const getAnchorTarget = (event: MouseEvent) => {
	let eTarget = event.target as HTMLElementTarget

	while (eTarget && (!eTarget.tagName || eTarget.tagName.toLowerCase() !== 'a')) {
		eTarget = eTarget.parentNode as HTMLElementTarget
	}
	return eTarget
}

const cancelEvent = (e: MouseEvent) => {
	e.stopPropagation()
	e.preventDefault()
	return false
}

const clickHandlerRegistrarFactory = (
	browserWindow: NonNullable<BrowserWindow>,
	{ getHandlers }: ILinkClickHandlers
): IPageDidMountHandler => {
	return {
		pageDidMount: () => {
			const onClick = async (e: MouseEvent) => {
				if (e.metaKey || e.ctrlKey) {
					return
				}

				const anchorTarget = getAnchorTarget(e)
				if (!anchorTarget) {
					return
				}
				if (anchorTarget.getAttribute('data-cancel-link')) {
					cancelEvent(e)
					return
				}
				const handlers = getHandlers()
				for (const handler of handlers) {
					const didHandle = handler.handleClick(anchorTarget)
					if (didHandle) {
						e.preventDefault()
						e.stopPropagation()
						return
					}
				}
			}
			browserWindow.addEventListener('click', onClick)

			return () => {
				browserWindow.removeEventListener('click', onClick)
			}
		},
	}
}

export const ClickHandlerRegistrar = withDependencies(
	[BrowserWindowSymbol, LinkClickHandlers],
	clickHandlerRegistrarFactory
)
