import type { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { LinkClickHandlers, name } from './symbols'
import { ClickHandlerRegistrar } from './clickHandlerRegistrar'
import { LifeCycle, IPageDidMountHandler } from '@wix/thunderbolt-symbols'
import { ClickHandlers } from './linkClickHandlers'

export const page: ContainerModuleLoader = (bind) => {
	bind(LinkClickHandlers).to(ClickHandlers)
	bind<IPageDidMountHandler>(LifeCycle.PageDidMountHandler).to(ClickHandlerRegistrar)
}

export { name }
